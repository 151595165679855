<template>
  <div>
    <div class="my-header-container row-between" ref="credit-header">
      <div class="row" style="cursor: pointer" @click="goPage('index')">
        <img
          class="icon-header"
          style="width: 40px; height: 40px"
          src="@A/images/icon_header.png"
          alt=""
        />
        <div
          class="primary logo-title"
          style="
            margin-left: 8px;
            font-size: 28px;
            font-weight: 500;
            line-height: 40px;
          "
        >
          智&nbsp;能&nbsp;证&nbsp;件&nbsp;照
        </div>
      </div>
      <div class="row">
        <el-button
          class="text-btn"
          style="font-size: 16px"
          type="text"
          @click="goPage('find')"
          >找回照片</el-button
        >
        <!-- <div class="text-btn lg">找回照片</div> -->
        <div
          class="app-text-btn"
          style="margin-left: 72px; font-size: 16px"
          v-if="false"
        >
          APP下载
          <div class="float-container">
            <div class="float-title">请选择相应客户端使用手机二维码下载</div>
            <div class="row-center">
              <div class="qr-code-container row">
                <!-- <div class="column-center">
                                    <div style="width: 240px;height: 240px;"></div>
                                    <div class="row">
                                        <img style="width: 32px;height: 32px" src="@A/images/icon_IOS.png" alt="">
                                        <div class="platform-text">IOS</div>
                                    </div>
                                </div> -->
                <div class="column-center">
                  <img
                    style="width: 240px; height: 240px"
                    src="@A/images/android_code.png"
                    alt=""
                  />
                  <div class="row" style="margin-top: 24px">
                    <img
                      style="width: 32px; height: 32px"
                      src="@A/images/icon_android.png"
                      alt=""
                    />
                    <div class="platform-text">Android</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :style="{ height: headerHeight + 'px' }"></div>
  </div>
</template>

<script>
import { apkChannel } from "@U/versionConfig";
export default {
  data() {
    return {
      isScroll: false,
      headerHeight: 60,
    };
  },
  mounted() {
    console.log(this.$refs["credit-header"].clientHeight);
    this.headerHeight = this.$refs["credit-header"].clientHeight;
    // window.addEventListener('scroll', this.handleScroll.bind(this))
  },
  methods: {
    handleScroll(e) {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > this.$refs["credit-header"].clientHeight) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    },
    goPage(name) {
      this.$router.push({
        name,
        query: {
          channel: localStorage.getItem("apkChannel") || apkChannel,
          apkChannel: localStorage.getItem("apkChannel") || apkChannel,
          bd_vid: this.$route.query.bd_vid || localStorage.getItem("bd_vid"),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-header-container {
  width: 100%;
  min-width: 1280px;
  background-color: white;
  box-sizing: border-box;
  padding: 32px 720px;
  top: 0;
  position: fixed;
  z-index: 1997;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .icon-header {
    cursor: pointer;
  }
  .logo-title {
    font-family: PingFangSC-Semibold, PingFang SC;
  }
  .text-btn {
    font-weight: 600;
    cursor: pointer;
    color: #111;
    &:hover {
      color: #409eff;
    }
  }
  .app-text-btn {
    font-weight: 600;
    cursor: pointer;
    color: #111;
    position: relative;
    .float-container {
      display: none;
    }
    &:hover {
      color: $--color-primary;
      & .float-container {
        display: flex;
        flex-direction: column;
        position: absolute;
        padding: 170px 200px 150px;
        min-width: 1076px;
        left: 50%;
        transform: translateX(-50%);
        top: 60px;
        background-image: url(../assets/images/float-bg.png);
        background-size: 100% 100%;
        .float-title {
          font-size: 40px;
          color: #111111;
        }
        .qr-code-container {
          margin-top: 64px;
          .platform-text {
            font-size: 40px;
            color: #111;
            font-weight: 500;
            line-height: 56px;
            margin-left: 16px;
          }
        }
      }
    }
  }
}
</style>
